import React, { ReactElement } from "react";
import { Avatar, Flex, List } from "antd";
import {
	VehicleAmount,
	VehicleType,
} from "../../../../utilities/api/jelbi-dashboard-api";
import styles from "./index.module.scss";
import VehicleImage from "../../../VehicleImage";
import { getVehicleTypeName } from "../../../../utilities/client/vehicles";
import MspImage from "../../../MspImage/MspImage";
import VehicleAccumulatedLoadingCard from "../VehicleAccumulatedLoadingCard";
import {
	VEHCILE_ACCUMULATED_INNER_ICON_MARGIN,
	VEHICLE_ACCUMULATED_AVATAR_SIZE,
	VehicleAccumulatedInnerProps,
	AccumulatedVehicleAmount,
	getAccumulatedVehicleAmountData,
} from "../../../../utilities/client/vehicle-accumulated";

function VehicleAccumulatedList({
	availabilities,
	showMsps,
	availableVehicleTypes,
	showLoading,
}: VehicleAccumulatedInnerProps): ReactElement {
	const accumulatedVehicleAmount = getAccumulatedVehicleAmountData(
		availableVehicleTypes,
		availabilities,
		showMsps
	);

	const getVehicleTypeInfo = (vehicleType: VehicleType) => (
		<Flex gap={4} vertical={false} className={styles["avatar-container"]}>
			<div className={styles.vehicle}>
				<Avatar
					className={styles["vehicle--avatar"]}
					size={VEHICLE_ACCUMULATED_AVATAR_SIZE}
					icon={
						<VehicleImage
							size={
								VEHICLE_ACCUMULATED_AVATAR_SIZE -
								VEHCILE_ACCUMULATED_INNER_ICON_MARGIN
							}
							vehicleType={vehicleType}
						/>
					}
				/>
			</div>
			<div className={styles["avatar-text"]}>
				<p>{getVehicleTypeName(vehicleType)}</p>
			</div>
		</Flex>
	);

	const getAmount = ({
		amount,
		mspDistributions,
	}: AccumulatedVehicleAmount) => {
		let vehicleAmountDescription;

		if (showMsps && mspDistributions) {
			const zeroAmount = <div>0</div>;

			vehicleAmountDescription = mspDistributions.length
				? mspDistributions.map((mspDistribution: VehicleAmount) =>
						mspDistribution.mspId ? (
							<Flex
								key={mspDistribution.mspId}
								className={styles["vehicle-amount__msp-row"]}
								gap={8}
							>
								<div className={styles.amount}>{mspDistribution.amount}</div>

								<MspImage mspId={mspDistribution.mspId} />
							</Flex>
						) : (
							zeroAmount
						)
					)
				: zeroAmount;
		} else {
			vehicleAmountDescription = <div>{amount ?? 0}</div>;
		}

		return (
			<Flex
				gap={4}
				vertical
				justify="center"
				className={styles["vehicle-amount"]}
			>
				<div className={styles["vehicle-amount__container"]}>
					{vehicleAmountDescription}
				</div>
			</Flex>
		);
	};

	return !showLoading ? (
		<div className={styles["vehicle-accumulated-table"]}>
			<List
				itemLayout="horizontal"
				dataSource={accumulatedVehicleAmount}
				renderItem={(vehicleAmount: AccumulatedVehicleAmount) => {
					return (
						<List.Item>
							<List.Item.Meta
								style={{ alignItems: "center" }}
								avatar={getVehicleTypeInfo(vehicleAmount.vehicleType)}
								description={getAmount(vehicleAmount)}
							/>
						</List.Item>
					);
				}}
			/>
		</div>
	) : (
		<VehicleAccumulatedLoadingCard />
	);
}

export default VehicleAccumulatedList;
