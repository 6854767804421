import { Station, StationState } from "../api/jelbi-dashboard-api";

const filterForExistingStations = (stationsList: Station[] | undefined) => {
	return stationsList
		?.filter((station) => station.state !== StationState.Inactive)
		.map((station) => {
			return {
				key: station.id,
				value: `${station.shortName} ${station.name}`,
			};
		});
};

export default filterForExistingStations;
