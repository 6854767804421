import { CloseOutlined, PlusOutlined } from "@ant-design/icons";
import { App, Upload } from "antd";
import React, {
	ReactElement,
	Dispatch,
	SetStateAction,
	useState,
	useEffect,
} from "react";
import { UploadFile } from "antd/lib/upload/interface";
import imageCompression from "browser-image-compression";
import { RcFile } from "antd/es/upload";
import equal from "fast-deep-equal";
import { postPhoto } from "../../utilities/api/jelbi-dashboard-api";

export type UploadedPhoto = {
	id: string;
	file: UploadFile;
};

type PhotoUploadProps = {
	maxItems: number;
	onChange?: Dispatch<SetStateAction<UploadedPhoto[]>>;
	initialPhotos: UploadedPhoto[];
};

async function toBase64(photo: File) {
	return new Promise<string>((resolve) => {
		const reader = new FileReader();
		reader.onload = () => {
			resolve(reader.result as string);
		};
		reader.readAsDataURL(photo);
	});
}

const uploadButton = (
	<button style={{ border: 0, background: "none" }} type="button">
		<PlusOutlined />
		<div style={{ marginTop: 8 }}>Upload</div>
	</button>
);

function PhotoUpload({
	maxItems,
	onChange,
	initialPhotos,
}: PhotoUploadProps): ReactElement {
	const { message } = App.useApp();
	const [photos, setPhotos] = useState<UploadedPhoto[]>(initialPhotos);

	useEffect(() => {
		if (onChange && !equal(initialPhotos, photos)) {
			onChange(photos);
		}
	}, [photos, onChange, initialPhotos]);

	const showPhotoPreviewUploadError = (
		uuid: string,
		file?: { rcFile: RcFile; thumbUrl: string }
	) => {
		setPhotos((prevPhotos: UploadedPhoto[]) =>
			prevPhotos.map((value) => {
				if (value.file.uid === uuid) {
					return {
						...value,
						file: {
							...value.file,
							...file,
							status: "error",
						},
					};
				}
				return value;
			})
		);

		message.error({
			content: "Fotoupload fehlgeschlagen",
			key: "failAddPhoto",
		});
	};

	return (
		<Upload
			accept="image/jpeg"
			maxCount={maxItems}
			listType="picture-card"
			showUploadList={{
				showPreviewIcon: false,
				removeIcon: <CloseOutlined />,
			}}
			fileList={photos.map((photo) => photo.file)}
			customRequest={async ({ file }) => {
				const options = {
					maxSizeMB: 1,
					maxWidthOrHeight: 1920,
					useWebWorker: true,
				};

				// the documentation https://ant.design/components/upload#how-to-use-customrequest
				// states file to be of type File, since antd's RcFile is an extension of File and
				// antd defines file to be string | Blob | RcFile we assume this type assertion
				const rcFile = file as RcFile;

				try {
					setPhotos((prevPhotos: UploadedPhoto[]) =>
						prevPhotos.concat([
							{
								id: `temp_${rcFile.uid}`,
								file: {
									...rcFile,
									percent: 50,
									status: "uploading",
								},
							},
						])
					);

					const compressedFile = await imageCompression(rcFile, options);
					const base64DataUrl = await toBase64(compressedFile);

					const { status, data } = await postPhoto({
						data: base64DataUrl,
					});

					if (status !== 201) {
						showPhotoPreviewUploadError(rcFile.uid, {
							rcFile,
							thumbUrl: base64DataUrl,
						});
					} else {
						setPhotos((prevPhotos: UploadedPhoto[]) =>
							prevPhotos.map((value) => {
								if (value.file.uid === rcFile.uid) {
									return {
										id: data.id,
										file: { ...rcFile, thumbUrl: base64DataUrl },
									};
								}
								return value;
							})
						);
					}
				} catch (error) {
					showPhotoPreviewUploadError(rcFile.uid);
				}
			}}
			onRemove={(file: UploadFile) => {
				setPhotos(
					photos
						.map((photo) => photo)
						.filter((photoItem) => photoItem.file.uid !== file.uid)
				);
			}}
		>
			{photos.length < maxItems ? uploadButton : undefined}
		</Upload>
	);
}

export default PhotoUpload;
