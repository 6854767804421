import React, { ReactElement, useState } from "react";
import { Button, Col, Row } from "antd";
import { PlusOutlined } from "@ant-design/icons";
import styles from "./index.module.scss";
import InspectionsTable from "./InspectionsTable";
import AddInspection from "./AddInspection";

function StationInspections(): ReactElement {
	const [isAddInspectionModalVisible, setAddInspectionModalVisible] =
		useState<boolean>(false);

	return (
		<div className={styles["station-inspections"]}>
			<Row>
				<Col span={24}>
					<h2 className={styles["station-inspections__title"]}>Begehungen</h2>
				</Col>
				<Col span={24}>
					<Button
						className={styles["station-inspections__new-inspection"]}
						type="primary"
						size="middle"
						icon={<PlusOutlined />}
						onClick={() => setAddInspectionModalVisible(true)}
						title="Neue Begehung anlegen"
					>
						Neue Begehung
					</Button>
				</Col>
			</Row>

			<InspectionsTable inspectionsPerPage={7} />
			<AddInspection
				isModalVisible={isAddInspectionModalVisible}
				setModalVisible={setAddInspectionModalVisible}
			/>
		</div>
	);
}

export default StationInspections;
