import React, { ReactElement } from "react";
import Table, { ColumnsType } from "antd/lib/table";
import { Avatar } from "antd";
import { VehicleAmount } from "../../../../utilities/api/jelbi-dashboard-api";
import styles from "./index.module.scss";
import VehicleImage from "../../../VehicleImage";
import { getVehicleTypeName } from "../../../../utilities/client/vehicles";
import MspImage from "../../../MspImage/MspImage";
import VehicleAccumulatedLoadingCard from "../VehicleAccumulatedLoadingCard";
import {
	VEHCILE_ACCUMULATED_INNER_ICON_MARGIN,
	VEHICLE_ACCUMULATED_AVATAR_SIZE,
	VehicleAccumulatedInnerProps,
	getAccumulatedVehicleAmountData,
} from "../../../../utilities/client/vehicle-accumulated";

function VehicleAccumulatedTable({
	availabilities,
	showMsps,
	availableVehicleTypes,
	showLoading,
}: VehicleAccumulatedInnerProps): ReactElement {
	const vehicleAmountTableRecords = getAccumulatedVehicleAmountData(
		availableVehicleTypes,
		availabilities,
		showMsps
	).map((dataItem) => ({
		[`${dataItem.vehicleType}-amount`]:
			dataItem.amount ?? dataItem.mspDistributions,
	}));

	const vehicleAccumulatedColumns: ColumnsType<
		Record<string, number | VehicleAmount[]>
	> = availableVehicleTypes.map((vehicleTypeEnum) => {
		return {
			key: `vehicleType-${vehicleTypeEnum}`,
			className: styles["column-title"],
			title: (
				<div>
					<Avatar
						className={styles["column-title--avatar"]}
						size={VEHICLE_ACCUMULATED_AVATAR_SIZE}
						icon={
							<VehicleImage
								size={
									VEHICLE_ACCUMULATED_AVATAR_SIZE -
									VEHCILE_ACCUMULATED_INNER_ICON_MARGIN
								}
								vehicleType={vehicleTypeEnum}
							/>
						}
					/>
					<p className={styles["column-title--avatar-text"]}>
						{getVehicleTypeName(vehicleTypeEnum)}
					</p>
				</div>
			),
			dataIndex: `${vehicleTypeEnum}-amount`,
			width: 50,
			render: (amount: number | VehicleAmount[]) => {
				// with msp
				if (showMsps && Array.isArray(amount)) {
					const zeroAmount = (
						<p
							className={`${styles["vehicle-accumulated-per-msp-item__amount"]} ${styles["vehicle-accumulated-per-msp-item__amount--without-msp"]}`}
						>
							0
						</p>
					);
					if (amount.length > 0) {
						return amount.map((vehicleAmount: VehicleAmount) =>
							vehicleAmount.mspId ? (
								<div
									key={vehicleAmount.mspId}
									className={
										styles["vehicle-accumulated-per-msp-item__msp-row"]
									}
								>
									<MspImage mspId={vehicleAmount.mspId} />
									<p
										className={
											styles["vehicle-accumulated-per-msp-item__amount"]
										}
									>
										{vehicleAmount.amount}
									</p>
								</div>
							) : (
								zeroAmount
							)
						);
					}
					return zeroAmount;
				}
				// without msp
				return (
					<p
						className={`${styles["vehicle-accumulated-per-msp-item__amount"]} ${styles["vehicle-accumulated-per-msp-item__amount--without-msp"]}`}
					>
						{amount as number}
					</p>
				);
			},
			align: "center",
		};
	});

	return !showLoading ? (
		<div className={styles["vehicle-accumulated-table"]}>
			<Table
				dataSource={[Object.assign({}, ...vehicleAmountTableRecords)]}
				size="middle"
				tableLayout="fixed"
				columns={vehicleAccumulatedColumns}
				pagination={false}
				rowKey={() => `single-row-accumulated-vehicles`}
			/>
		</div>
	) : (
		<VehicleAccumulatedLoadingCard />
	);
}

export default VehicleAccumulatedTable;
