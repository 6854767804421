import React, { ReactElement, useState } from "react";
import { Card, Row, Col, Select, Spin, Typography, Flex } from "antd";
import { Column } from "@ant-design/plots";
import {
	getHours,
	startOfDay,
	eachDayOfInterval,
	subDays,
	isToday,
} from "date-fns";
import { useQuery } from "@tanstack/react-query";
import { LoadingOutlined } from "@ant-design/icons";
import { Station } from "../../../utilities/api/jelbi-dashboard-api";
import { getVehicleTypeName } from "../../../utilities/client/vehicles";
import distributionService from "../../../services/distributionService";
import { getWeekdayName } from "../../../utilities/client/date-format";
import JelbiColor from "../../../utilities/client/jelbi-colors";
import { getVehicleAvailabilityDayPerHourQueryKey } from "../../../utilities/client/query-keys";

import styles from "./index.module.scss";

const { Text } = Typography;

type DropdownOption = {
	key: string;
	label: string;
	value: string;
};

type VehicleDistributionDataSetDay = {
	dateLabel: number;
	amount?: number;
	vehicleLabel?: string;
};

type StationVehicleAvailabilityProps = {
	station: Station;
};

const generateDropdownOptions = (): DropdownOption[] => {
	const weekDays = eachDayOfInterval({
		start: subDays(new Date(), 7),
		end: new Date(),
	});

	return weekDays.map((weekday) => {
		return {
			key: weekday.toISOString(),
			label: isToday(weekday) ? "Heute" : getWeekdayName(weekday),
			value: weekday.toISOString(),
		};
	});
};

function StationVehicleAvailability({
	station,
}: StationVehicleAvailabilityProps): ReactElement {
	const [weekDays] = useState<DropdownOption[]>(generateDropdownOptions());

	const [selectedWeekDay, setSelectedWeekDay] = useState<DropdownOption>(
		weekDays[weekDays.length - 1]
	);

	const vehicleAvailabilityResponse = useQuery({
		queryKey: getVehicleAvailabilityDayPerHourQueryKey(
			new Date(selectedWeekDay.value),
			station.id
		),

		queryFn: () =>
			distributionService.fetchAvailabilitiesDayPerHour(
				[station.id],
				startOfDay(new Date(selectedWeekDay.value))
			),
	});

	if (vehicleAvailabilityResponse.isError) {
		return <span>Etwas ist schief gelaufen</span>;
	}

	const vehicleAvailabilityDataSetDay: VehicleDistributionDataSetDay[] = [];
	if (vehicleAvailabilityResponse.data) {
		vehicleAvailabilityResponse.data.results.forEach(
			(vehicleAvailabilityHour) => {
				if (vehicleAvailabilityHour.vehicleDistributions.length !== 0) {
					vehicleAvailabilityHour.vehicleDistributions.forEach(
						(vehicleDistribution) => {
							vehicleAvailabilityDataSetDay.push({
								dateLabel: getHours(new Date(vehicleAvailabilityHour.start)),
								amount: vehicleDistribution.amount,
								vehicleLabel: getVehicleTypeName(
									vehicleDistribution.vehicleType
								),
							});
						}
					);
				} else {
					vehicleAvailabilityDataSetDay.push({
						dateLabel: getHours(new Date(vehicleAvailabilityHour.start)),
					});
				}
			}
		);
	}

	return (
		<div className={styles["vehicle-availability"]}>
			<Row align="middle" className={styles.header}>
				<Col span={12}>
					<span className={styles["graph-title"]}>
						Verfügbarkeiten der letzten 7 Tage
					</span>
				</Col>
				<Col span={7} offset={5}>
					<Select
						className={styles["week-day-select"]}
						options={weekDays}
						defaultValue={selectedWeekDay.label}
						placeholder="Bitte wähle einen Tag aus"
						onChange={(value) => {
							setSelectedWeekDay({
								key: value,
								label: value,
								value: new Date(value).toISOString(),
							});
						}}
					/>
				</Col>
			</Row>
			{vehicleAvailabilityDataSetDay.length !== 0 ? (
				<Row>
					<Col span={24}>
						<Card className={styles["graph-container"]}>
							<Row align="middle" justify="center">
								{/* diagram */}
								<Col span={24}>
									<Column
										className={`${styles["graph-container"]} ${styles["graph-container--data-container"]}`}
										data={vehicleAvailabilityDataSetDay}
										xField="dateLabel"
										yField="amount"
										colorField="vehicleLabel"
										stack
										axis={{
											x: { title: "Uhrzeit" },
										}}
										height={250}
										legend={false}
										interaction={{
											tooltip: {
												render: (e: unknown, { title }: { title: string }) => {
													// duplicated items are not always included in the items array
													const allItems = vehicleAvailabilityDataSetDay.filter(
														(dataSetItem) => dataSetItem.dateLabel === +title
													);
													return (
														<Flex gap="small" vertical>
															{allItems.map((item) => {
																return (
																	<Text>
																		{item.vehicleLabel}: {item.amount}
																	</Text>
																);
															})}
														</Flex>
													);
												},
											},
										}}
										style={{
											insetLeft: 2,
											insetRight: 2,
											fill: JelbiColor.MAIN,
										}}
									/>
								</Col>
							</Row>
						</Card>
					</Col>
				</Row>
			) : (
				<Row align="middle" justify="center" gutter={[16, 16]}>
					<Col span={24}>
						<Card
							className={`${styles["graph-container"]} ${styles["graph-container--empty"]}`}
						>
							<Spin
								className={styles.spinner}
								indicator={
									<LoadingOutlined className={styles["spinner--icon"]} spin />
								}
							/>
							<p className={styles["loading-text"]}>Wird geladen...</p>
						</Card>
					</Col>
				</Row>
			)}
		</div>
	);
}

export default StationVehicleAvailability;
