import React, { ReactElement } from "react";
import { MspId } from "../../utilities/api/jelbi-dashboard-api";
import useMspImage from "../../utilities/client/hooks/useMspImage";
import useMspName from "../../utilities/client/hooks/useMspName";

type MspImageProps = {
	mspId: MspId;
};

function MspImage({ mspId: id }: MspImageProps): ReactElement {
	const { PUBLIC_URL } = process.env;

	const imageId = useMspImage(id);
	const name = useMspName(id);

	const imgSrc = PUBLIC_URL + imageId;
	const text = name;

	return <img src={imgSrc} alt={text} title={text} width="35px" />;
}

export default MspImage;
