import React, { ReactElement, useEffect, useState } from "react";
import { Col, Row, Form, Button, Card } from "antd";
import { RightOutlined } from "@ant-design/icons";
import styles from "./index.module.scss";
import { humanizedDateTimeLong } from "../../../../utilities/client/date-format";
import { ReactComponent as ValidIcon } from "../../../../assets/icons/valid.svg";
import { InspectionFormData, IssueData } from "..";
import AddIssue from "../../../StationIssues/AddIssue";
import { InspectionIssueAdd } from "../../../../utilities/api/jelbi-dashboard-api";

type IssuesOverviewFormPageProps = {
	inspectionData: InspectionFormData;
	issuesData: IssueData[];
	completedIssues: Set<string>;
	onBack: () => void;
};

const COL_SPAN_FULL_ROW = 24;

function IssuesOverviewFormPage({
	inspectionData,
	issuesData,
	completedIssues,
	onBack,
}: IssuesOverviewFormPageProps): ReactElement {
	const [form] = Form.useForm();

	const [isAddIssueModalVisible, setAddIssueModalVisible] =
		useState<boolean>(false);
	const [clickedIssue, setClickedIssue] = useState<
		Partial<IssueData> & Pick<InspectionIssueAdd, "equipment">
	>({ equipment: "" });

	const [isSaveButtonEnabled, setIsSaveButtonEnabled] = useState(false);

	useEffect(() => {
		form
			.validateFields()
			.then(() => setIsSaveButtonEnabled(true))
			.catch((errorInfo) =>
				errorInfo.errorFields.length === 0 && errorInfo.outOfDate
					? setIsSaveButtonEnabled(true)
					: setIsSaveButtonEnabled(false)
			);
	}, [issuesData.length]);

	return (
		<>
			<Row gutter={[18, 12]} className={styles["overview-issues-wrapper"]}>
				<Col
					span={COL_SPAN_FULL_ROW}
					className={styles["overview-issues-label"]}
				>
					<p>STANDORT</p>
					<div>{inspectionData.station}</div>
				</Col>
				<Col
					span={COL_SPAN_FULL_ROW}
					className={styles["overview-issues-label"]}
				>
					<p>DATUM</p>
					<div>{humanizedDateTimeLong(inspectionData.createdAt)}</div>
				</Col>
				<Col span={COL_SPAN_FULL_ROW}>
					<Form
						name="overviewIssuesForm"
						onAbort={() => form.resetFields()}
						layout="vertical"
						form={form}
						className={styles["overview-issues-form"]}
						preserve={false}
					>
						<Form.Item
							label={
								<div className={styles["overview-issues-form__label"]}>
									PROBLEMMELDUNGEN
								</div>
							}
							name="issue-overview"
							rules={[
								{
									validator: () => {
										const completedIssueEquipments = new Set(
											issuesData.map((issue) => issue.equipment)
										);
										const uncompletedIssueEquipments =
											inspectionData.detectedIssues.filter(
												(detectedIssue) =>
													!completedIssueEquipments.has(detectedIssue)
											);
										if (uncompletedIssueEquipments.length) {
											return Promise.reject();
										}
										return Promise.resolve();
									},
									message:
										"Bitte fülle alle Problemmeldungen zu dieser Begehung aus",
								},
							]}
						>
							<Form.List name="issues">
								{() => (
									<>
										<div
											className={
												styles["overview-issues-form__label--description"]
											}
										>
											{inspectionData.detectedIssues.length ? (
												<>
													Bitte ergänze die Problemmeldung(en) mit
													Informationen.
													<p>
														Vollständig ausgefüllte Meldungen sind grün
														markiert.
													</p>
												</>
											) : (
												<>
													Für diese Begehung wurden keine Probleme bei der
													Ausstattung vermerkt.
												</>
											)}
										</div>

										{inspectionData.detectedIssues.map((detectedIssue) => (
											<Card
												className={styles.issue}
												key={detectedIssue}
												onClick={() => {
													setClickedIssue(
														issuesData.find(
															(issue) => issue.equipment === detectedIssue
														) || { equipment: detectedIssue }
													);
													setAddIssueModalVisible(true);
												}}
											>
												{completedIssues.has(detectedIssue) ? (
													<Card.Meta
														avatar={<ValidIcon />}
														title={
															<>
																{detectedIssue}
																<RightOutlined
																	className={styles.issue__chevron}
																/>
															</>
														}
													/>
												) : (
													<Card.Meta
														className={styles["issue__no-avatar"]}
														title={
															<>
																{detectedIssue}
																<RightOutlined
																	className={styles.issue__chevron}
																/>
															</>
														}
													/>
												)}
											</Card>
										))}
									</>
								)}
							</Form.List>
						</Form.Item>
						<Col
							span={COL_SPAN_FULL_ROW}
							className={styles["overview-issues-form__footer"]}
						>
							<Button type="default" onClick={onBack} className={styles.button}>
								Zurück
							</Button>
							<Button
								type="primary"
								htmlType="submit"
								className={styles.button}
								disabled={!isSaveButtonEnabled}
							>
								Speichern
							</Button>
						</Col>
					</Form>
				</Col>
			</Row>
			<AddIssue
				isModalVisible={isAddIssueModalVisible}
				setModalVisible={setAddIssueModalVisible}
				initialValues={{
					stationId: inspectionData.stationId,
					station: inspectionData.station,
					createdAt: inspectionData.createdAt,
					problem: undefined,
					description: undefined,
					photos: undefined,
					...clickedIssue,
				}}
				saveOnSubmit={false}
			/>
		</>
	);
}

export default IssuesOverviewFormPage;
