import React from "react";
import { Title, Text, Button, Container, Group, Anchor } from "@mantine/core";
import { useNavigate } from "react-router-dom";
import styles from "./index.module.scss";
import useIsMobileView from "../../utilities/client/hooks/useIsMobileView";

export default function NotFound() {
	const navigate = useNavigate();
	const isMobile = useIsMobileView();

	return (
		<Container className={styles.root}>
			<Title className={styles.title}>Hoppla!</Title>
			<Title className={styles.title}>Etwas ist schief gelaufen.</Title>
			<Text c="dimmed" size="lg" ta="center" className={styles.description}>
				Du hast eine ungültige URL eingegeben. Bitte wiederholen den Vorgang
				noch einmal und falls der Fehler immer noch besteht, schreibe eine
				E-Mail mit einer Erläuterung an:{" "}
				<Anchor href="mailto:Webentwicklung@bvg.de">
					Webentwicklung@bvg.de
				</Anchor>
			</Text>

			<Group justify="center">
				<Button size="md" fullWidth={isMobile} onClick={() => navigate(-1)}>
					Zurück
				</Button>
			</Group>
		</Container>
	);
}
