import generatePicker from "antd/lib/date-picker/generatePicker";
import dateFnsGenerateConfig from "rc-picker/lib/generate/dateFns";

const DatePicker = generatePicker<Date>(dateFnsGenerateConfig);

export const localeConfig = {
	lang: {
		locale: "de",
		today: "Heute",
		now: "Jetzt",
		backToToday: "Zurück zu heute",
		placeholder: "Datum auswählen",
		ok: "OK",
		clear: "Löschen",
		month: "Monat",
		year: "Jahr",
		timeSelect: "Zeit auswählen",
		dateSelect: "Datum auswählen",
		monthSelect: "Monat auswählen",
		yearSelect: "Jahr auswählen",
		decadeSelect: "Jahrzehnt auswählen",
		previousMonth: "Vorheriger Monat",
		nextMonth: "Nächster Monat",
		previousYear: "Vorheriges Jahr",
		nextYear: "Nächstes Jahr",
		previousDecade: "Vorheriges Jahrzehnt",
		nextDecade: "Nächstes Jahrzehnt",
		previousCentury: "Vorheriges Jahrhundert",
		nextCentury: "Nächstes Jahrhundert",
	},
	timePickerLocale: {
		placeholder: "Zeit auswählen",
	},
};

export default DatePicker;
