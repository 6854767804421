import { CloseOutlined } from "@ant-design/icons";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { App, Col, Form, Input, Modal, Row, Select } from "antd";
import React, { ReactElement, useState, useEffect } from "react";
import { differenceInMilliseconds } from "date-fns";
import {
	Issue,
	IssueAdd,
	IssueCollection,
	postIssue,
} from "../../../utilities/api/jelbi-dashboard-api";
import stationService from "../../../services/stationService";
import {
	QUERY_KEY_ALL_ISSUES,
	QUERY_KEY_ALL_STATIONS,
} from "../../../utilities/client/query-keys";
import styles from "./index.module.scss";
import PhotoUpload, { UploadedPhoto } from "../../PhotoUpload";
import filterForExistingStations from "../../../utilities/client/existing-stations-dropdown";
import useIsMobileView from "../../../utilities/client/hooks/useIsMobileView";
import DatePicker, { localeConfig } from "../../DatePicker/DatePicker";
import stationEquipmentOptions from "../../../utilities/client/station-equipment";
import useGetUserRoles from "../../../utilities/client/hooks/useGetUserRoles";
import { getHighestUserRole } from "../../../utilities/client/roles.util";
import { DATE_PICKER_FORMAT } from "../../../constants";

type AddIssueProps = {
	isModalVisible: boolean;
	setModalVisible: (isModalVisible: boolean) => void;
	initialValues?: {
		station: string;
		stationId: string;
		equipment: string;
		problem?: string;
		description?: string;
		photos?: UploadedPhoto[];
		createdAt?: Date;
	};
	saveOnSubmit?: boolean;
};

type DropdownOption = {
	key: string;
	value: string;
};

const COL_SPAN_FULL_ROW = 24;

function AddIssue({
	isModalVisible,
	setModalVisible,
	initialValues,
	saveOnSubmit = true,
}: AddIssueProps): ReactElement {
	const IS_CURRENT_VIEW_MOBILE = useIsMobileView();
	const COL_SPAN_RESPONSIVE = IS_CURRENT_VIEW_MOBILE ? 24 : 24 / 2;

	const [form] = Form.useForm();
	const queryClient = useQueryClient();

	const [stationDropdownOptions, setStationDropdownOptions] =
		useState<DropdownOption[]>();
	const [selectedStationId, setSelectedStationId] = useState<string>("");
	const [photosState, setPhotosState] = useState<UploadedPhoto[]>([]);
	const [isConfirmBtnEnabled, setIsConfirmBtnEnabled] = useState(false);
	const { message } = App.useApp();

	const currentUserRoles = useGetUserRoles();

	useEffect(() => {
		if (initialValues) {
			// the prop initialValues is not updated on state
			// changes, so, it does not update properly when
			// the form is already initialized
			// see https://ant.design/components/form#formitem
			form.setFieldsValue(initialValues);

			setSelectedStationId(initialValues.stationId);
			setPhotosState(initialValues.photos ? initialValues.photos : []);
		}
	}, [initialValues]);

	const resetControlledFields = () => {
		setSelectedStationId("");
		setPhotosState([]);
	};

	const resetForm = () => {
		form.resetFields();
		resetControlledFields();
		setModalVisible(false);
	};

	const { data: stations } = useQuery({
		queryKey: [QUERY_KEY_ALL_STATIONS],
		queryFn: async () => stationService.fetchStations(),
	});

	useEffect(() => {
		if (stations) {
			setStationDropdownOptions(filterForExistingStations(stations));
		}
	}, [stations?.length]);

	const updateQueryCache = async (addedIssue: Issue) => {
		const queryKey = [QUERY_KEY_ALL_ISSUES];
		await queryClient.cancelQueries({ queryKey });

		const previousIssues = queryClient.getQueryData<IssueCollection>(queryKey);

		if (previousIssues) {
			queryClient.setQueryData<IssueCollection>(queryKey, {
				totalCount: previousIssues.totalCount + 1,
				results: [addedIssue, ...previousIssues.results],
			});
		}
	};

	const onSubmitError = () => {
		message.error({
			content: "Problemmeldung nicht übermittelt!",
			key: "failAddIssue",
		});
	};

	const addIssueMutation = useMutation({
		mutationFn: async (issue: IssueAdd) => {
			const response = await postIssue(issue);

			if (response.status !== 201) {
				return Promise.reject();
			}
			return Promise.resolve(response.data);
		},

		onSuccess: (addedIssue: Issue) => {
			message.success({
				content: "Problemmeldung übermittelt!",
				key: "successAddIssue",
			});

			updateQueryCache(addedIssue);
			resetForm();
		},
		onError: onSubmitError,
	});

	const addIssue = ({
		createdAt,
		equipment,
		problem,
		description,
	}: {
		createdAt: string;
		equipment: string;
		problem: string;
		description: string;
	}) => {
		if (saveOnSubmit) {
			const currentUserHighestRole = getHighestUserRole(currentUserRoles);
			if (currentUserHighestRole) {
				addIssueMutation.mutate({
					stationId: selectedStationId,
					createdBy: currentUserHighestRole,
					createdAt,
					equipment,
					problem,
					description,
					photoIds: photosState
						.filter((photo) => !photo.id.includes("temp"))
						.map((photo) => photo.id),
				});
			} else {
				onSubmitError();
			}
		} else {
			resetForm();
		}
	};

	const onFieldsChangeHandle = () => {
		const { station, createdAt, equipment, problem, description, photos } =
			form.getFieldsValue();
		// enable the save button if the required fields are not empty
		if (
			station !== undefined &&
			createdAt !== undefined &&
			equipment !== undefined &&
			problem !== undefined &&
			description !== undefined &&
			((photos !== undefined &&
				!photos.some((photo: UploadedPhoto) => photo.id.includes("temp"))) ||
				photos === undefined)
		) {
			setIsConfirmBtnEnabled(true);
		} else {
			setIsConfirmBtnEnabled(false);
		}
	};

	return (
		<Modal
			title={
				<h2 className={styles["add-issue-modal__title"]}>
					Neue Problemmeldung
				</h2>
			}
			className="add-issue-modal"
			open={isModalVisible}
			onCancel={resetForm}
			okText={saveOnSubmit ? "Speichern" : "Weiter"}
			cancelText="Abbrechen"
			okButtonProps={{ disabled: !isConfirmBtnEnabled }}
			maskClosable={false}
			destroyOnClose
			closable
			closeIcon={
				<CloseOutlined className={styles["add-issue-modal__close-button"]} />
			}
			afterClose={() => setIsConfirmBtnEnabled(false)}
			width={1000}
			onOk={form.submit}
		>
			<Form
				name="addIssueForm"
				onFinish={addIssue}
				onFieldsChange={onFieldsChangeHandle}
				layout="vertical"
				form={form}
				className={styles["add-issue-form"]}
				preserve={false}
				initialValues={{
					createdAt: new Date(),
					...initialValues,
				}}
			>
				<Row gutter={[18, 12]}>
					<Col span={COL_SPAN_RESPONSIVE}>
						<Form.Item
							name="station"
							label="STANDORT"
							rules={[
								{
									required: true,
									message: "Bitte wähle eine Station aus",
								},
							]}
						>
							<Select
								showSearch
								className={styles["add-issue-form__input"]}
								placeholder="Bitte wählen"
								options={stationDropdownOptions}
								onSelect={(_, option) => {
									if (option.key) {
										setSelectedStationId(option.key.toString());
									}
								}}
								disabled={!!initialValues}
							/>
						</Form.Item>
					</Col>
					<Col span={COL_SPAN_RESPONSIVE}>
						<Form.Item
							name="createdAt"
							label="DATUM"
							rules={[
								{
									required: true,
									message: "Bitte füge ein Datum hinzu",
								},
								() => ({
									validator(_, input) {
										if (differenceInMilliseconds(input, new Date()) > 0) {
											return Promise.reject(
												new Error("Bitte wähle ein Datum in der Vergangenheit")
											);
										}
										return Promise.resolve();
									},
								}),
							]}
						>
							<DatePicker
								className={styles["add-issue-form__input"]}
								format={DATE_PICKER_FORMAT}
								locale={localeConfig}
								disabled={!!initialValues}
							/>
						</Form.Item>
					</Col>
				</Row>
				<Row gutter={[18, 12]}>
					<Col span={COL_SPAN_RESPONSIVE}>
						<Form.Item
							name="equipment"
							label="AUSSTATTUNG"
							rules={[
								{
									required: true,
									message: "Bitte wähle eine Ausstattung aus",
								},
							]}
						>
							<Select
								showSearch
								className={styles["add-issue-form__input"]}
								placeholder="Bitte wählen"
								options={stationEquipmentOptions}
								disabled={!!initialValues}
							/>
						</Form.Item>
					</Col>
					<Col span={COL_SPAN_RESPONSIVE}>
						<Form.Item
							name="problem"
							label="PROBLEM"
							rules={[
								{
									required: true,
									message: "Bitte wähle ein Problem aus",
								},
							]}
						>
							<Select
								showSearch
								className={styles["add-issue-form__input"]}
								placeholder="Bitte wählen"
							>
								<Select.Option value="Beschädigung">Beschädigung</Select.Option>
								<Select.Option value="Defekt">Defekt</Select.Option>
								<Select.Option value="Verkehrssicherheit">
									Verkehrssicherheit
								</Select.Option>
								<Select.Option value="Verschmutzung">
									Verschmutzung
								</Select.Option>
								<Select.Option value="Sonstiges">Sonstiges</Select.Option>
							</Select>
						</Form.Item>
					</Col>
				</Row>
				<Row gutter={[18, 12]}>
					<Col span={COL_SPAN_FULL_ROW}>
						<Form.Item
							name="description"
							label="BESCHREIBUNGSTEXT"
							rules={[
								{
									required: true,
									message: "Bitte füge eine Beschreibung hinzu",
								},
								{
									max: 255,
									message:
										"Der Beschreibungstext darf maximal 255 Zeichen lang sein",
								},
							]}
						>
							<Input.TextArea
								className={styles["add-issue-form__input"]}
								rows={4}
								placeholder="Bitte beschreibe das Problem möglichst genau."
							/>
						</Form.Item>
					</Col>
				</Row>
				<Row gutter={[18, 12]}>
					<Col span={COL_SPAN_FULL_ROW}>
						<Form.Item label={`FOTOS (${photosState.length}/5)`} name="photos">
							<PhotoUpload
								maxItems={5}
								initialPhotos={photosState}
								onChange={setPhotosState}
							/>
						</Form.Item>
					</Col>
				</Row>
			</Form>
		</Modal>
	);
}

export default AddIssue;
