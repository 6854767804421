import React, { ReactElement } from "react";
import { LoadingOverlay } from "@mantine/core";
import { useQuery } from "@tanstack/react-query";
import { ok } from "@oazapfts/runtime";
import { useParams } from "react-router-dom";
import { getMsp } from "../../../utilities/api/jelbi-dashboard-api";
import MspAddEditForm from "./MspAddEditForm";
import { getMspQueryKey } from "../../../utilities/client/query-keys";
import NotFound from "../../NotFound";

function EditMsp(): ReactElement {
	const { mspId } = useParams();

	const { data: msp, isPending } = useQuery({
		queryKey: getMspQueryKey(mspId || ""),
		queryFn: () => ok(getMsp(mspId || "")),
		// The query will not execute until routeMatch.params.mspId
		enabled: !!mspId,
	});

	if (isPending) {
		return <LoadingOverlay visible />;
	}
	if (!msp) {
		return <NotFound />;
	}
	return <MspAddEditForm msp={msp} />;
}

export default EditMsp;
