// ways to access mantine's theme: https://mantine.dev/theming/functions/
// theme object: https://mantine.dev/theming/mantine-provider/#theme-object

import {
	MantineTheme,
	MantineThemeOverride,
	ButtonProps,
	InputProps,
} from "@mantine/core";

export const jelbiDashboardTheme: MantineThemeOverride = {
	// font
	fontFamily: "Transit",

	// colors
	colors: {
		// generates the different gradients: https://colorkit.co/color-shades-generator/
		blue: [
			"#e2f6ff",
			"#cbe9ff",
			"#99cfff",
			"#62b5ff",
			"#369eff",
			"#1890ff",
			"#0089ff",
			"#0076e5",
			"#0069ce",
			"#005ab7",
		],
	},
	primaryColor: "blue",

	// Controls focus ring styles
	focusRing: "auto",

	// h1-h6 styles, used in Title and TypographyStylesProvider components
	headings: {
		sizes: {
			h1: { fontSize: "24px" },
			h2: { fontSize: "22px" },
			h3: { fontSize: "20px" },
			h4: { fontSize: "18px" },
		},
	},

	fontSizes: {
		xs: "12px",
		sm: "14px",
		md: "16px",
		lg: "18px",
		xl: "20px",
	},

	breakpoints: {
		xs: "480px",
		sm: "576px",
		md: "768px",
		lg: "992px",
		xl: "1920px",
	},

	other: {
		primaryColor: "blue",
	},

	components: {
		Button: {
			styles: (_theme: MantineTheme, { variant }: ButtonProps) => ({
				root: {
					height: "44px",
					borderRadius: "8px",
					padding: "0px 14px",
					lineHeight: "28px",
					fontWeight: "normal",

					...(variant === "subtle" && {
						backgroundColor: "#fff",
						borderBottom: "1px solid var(--mantine-primary-color-6)",
						borderRadius: "0",
						marginTop: "8px",
						padding: "0 2px",
						height: "22px",
					}),
				},
			}),
		},
		ActionIcon: {
			styles: () => ({
				root: {
					height: "44px",
					width: "44px",
					borderRadius: "8px",
				},
			}),
		},
		Input: {
			styles: (theme: MantineTheme, { error }: InputProps) => ({
				input: {
					height: "44px",
					color: theme.colors.dark[9],
					borderRadius: "2px",

					...(error && {
						border: `1px solid ${theme.colors.red[8]}`,
					}),
				},
			}),
		},
		InputWrapper: {
			styles: (theme: MantineTheme) => ({
				label: { textTransform: "uppercase", paddingBottom: "8px" },
				error: {
					fontSize: theme.fontSizes.sm,
				},
			}),
		},
		Textarea: {
			styles: () => ({
				input: {
					paddingTop: "10px",
					paddingBottom: "10px",
				},
			}),
		},
		Checkbox: {
			styles: (_: MantineTheme, params: { size: string }) => ({
				label: {
					fontSize: params.size === "md" ? "14px" : undefined,
				},
				inner: {
					margin: "auto 0",
				},
			}),
		},
		Table: {
			styles: (theme: MantineTheme) => ({
				thead: { backgroundColor: theme.colors.gray[1] },
				tr: { height: "44px" },
			}),
		},
	},
};

export default jelbiDashboardTheme;
