import React, { ReactElement } from "react";
import { Card, Col, Row, Spin } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import styles from "./index.module.scss";

function VehicleAccumulatedLoadingCard(): ReactElement {
	return (
		<Row align="middle" justify="center" style={{ width: "100%" }}>
			<Col span={24}>
				<Card className={styles["empty-container"]}>
					<Spin
						className={styles.spinner}
						indicator={
							<LoadingOutlined className={styles["spinner--icon"]} spin />
						}
					/>
					<p className={styles["loading-text"]}>Wird geladen...</p>
				</Card>
			</Col>
		</Row>
	);
}

export default VehicleAccumulatedLoadingCard;
