import { $enum } from "ts-enum-util";
import { VehicleType } from "../api/jelbi-dashboard-api";

export const getVehicleTypeImage = (
	vehicleType: VehicleType | undefined
): string => {
	return $enum.mapValue(vehicleType).with({
		CAR: "/icons-vehicle/car.svg",
		MOTOR_SCOOTER: "/icons-vehicle/motor-scooter.svg",
		KICK_SCOOTER: "/icons-vehicle/kick-scooter.svg",
		BICYCLE: "/icons-vehicle/bicycle.svg",
		PRIVATE: "",
		STATION_BASED_CAR: "/icons-vehicle/station-based-car.svg",
		[$enum.handleUndefined]: "Undefined",
	});
};

export const getVehicleTypeName = (
	vehicleType: VehicleType | undefined
): string => {
	return $enum.mapValue(vehicleType).with({
		CAR: "Auto (flexibel)",
		MOTOR_SCOOTER: "E-Roller",
		KICK_SCOOTER: "E-Scooter",
		BICYCLE: "Fahrrad",
		PRIVATE: "Privat",
		STATION_BASED_CAR: "Auto (stationär)",
		[$enum.handleUndefined]: "Undefined",
	});
};

// This is the order for the mode overview (StationInformation)
export const getVehicleOrder = (
	vehicleType: VehicleType | undefined
): number => {
	return $enum.mapValue(vehicleType).with({
		CAR: 1,
		MOTOR_SCOOTER: 3,
		KICK_SCOOTER: 5,
		BICYCLE: 4,
		PRIVATE: 6,
		STATION_BASED_CAR: 2,
		[$enum.handleUndefined]: 7,
	});
};

export const isMicromobility = (mode: VehicleType): boolean => {
	return (
		mode === VehicleType.MotorScooter ||
		mode === VehicleType.KickScooter ||
		mode === VehicleType.Bicycle
	);
};
