import { useEffect, useState } from "react";
import styles from "../../../styles/global-variables.module.scss";

const breakpointScreenXL = Number(styles["breakpoint-screen-lg"].slice(0, -2));

const useIsMobileView = (): boolean => {
	const [screenWidth, setScreenWidth] = useState(window.innerWidth);

	useEffect(() => {
		const handleResize = () => {
			setScreenWidth(window.innerWidth);
		};

		window.addEventListener("resize", handleResize);

		// Clean up the event listener when the component unmounts
		return () => {
			window.removeEventListener("resize", handleResize);
		};
	}, []);

	return screenWidth < breakpointScreenXL;
};

export default useIsMobileView;
