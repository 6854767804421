import React, { ReactElement, useEffect, useState } from "react";
import { App, Result, Table } from "antd";
import { ColumnsType } from "antd/lib/table";
import { subYears } from "date-fns";
import { useQuery, useQueryClient, useMutation } from "@tanstack/react-query";
import { useNavigate, useParams } from "react-router-dom";
import { FilterValue } from "antd/lib/table/interface";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import {
	filterInspectionsForMobile,
	getInspectionTableConfigDesktop,
	getInspectionTableConfigMobile,
} from "./tableConfig";
import inspectionService from "../../../services/inspectionService";
import stationService from "../../../services/stationService";
import { Inspection } from "../../../utilities/api/jelbi-dashboard-api";
import {
	QUERY_KEY_ALL_INSPECTIONS,
	QUERY_KEY_ALL_STATIONS,
} from "../../../utilities/client/query-keys";
import styles from "./index.module.scss";
import useIsMobileView from "../../../utilities/client/hooks/useIsMobileView";
import ReadInspection from "../ReadInspection";
import { isClickOnRowValidConditions } from "../../../utilities/client/table-configurations";
import useGetUserRoles from "../../../utilities/client/hooks/useGetUserRoles";

type InspectionsTableProps = {
	inspectionsPerPage: number;
};

function InspectionsTable({
	inspectionsPerPage,
}: InspectionsTableProps): ReactElement {
	const userRoles = useGetUserRoles();
	const [selectedInspection, setSelectedInspection] = useState<Inspection>();
	const [readInspectionKeySuffix, setReadInspectionKeySuffix] =
		useState<boolean>(false);
	const [tableFilters, setTableFilters] = useState<
		Record<string, FilterValue | null>
	>({});
	const { modal } = App.useApp();
	const isViewMobile = useIsMobileView();
	const queryClient = useQueryClient();
	const {
		data: inspections,
		isPending,
		isError,
		error,
	} = useQuery({
		queryKey: [QUERY_KEY_ALL_INSPECTIONS],
		queryFn: () => {
			const endDate = new Date().toISOString();
			const startDate = subYears(endDate, 1).toISOString();
			return inspectionService.fetchInspections(startDate, endDate);
		},
	});

	const { data: stations } = useQuery({
		queryKey: [QUERY_KEY_ALL_STATIONS],
		queryFn: () => stationService.fetchStations(),
	});

	const deleteMutation = useMutation({
		mutationFn: (inspectionId: string) => {
			return inspectionService.removeInspection(inspectionId);
		},

		onSuccess: () => {
			queryClient.invalidateQueries({
				queryKey: [QUERY_KEY_ALL_INSPECTIONS],
			});
		},
	});

	// useHistory
	const navigate = useNavigate();
	const { inspectionId } = useParams();

	useEffect(() => {
		if (inspectionId) {
			const sharedInspection = inspections?.results.find(
				(inspection) => inspection.id === inspectionId
			);
			if (sharedInspection) {
				setSelectedInspection(sharedInspection);
			}
		}
	});

	const { results } = inspections || {
		results: [],
	};

	const stationNames = Array.from(
		new Set(results.map((result) => result.station.name || "unbekannt"))
	);

	const confirmDeleteInspection = (deleteInspectionId: string) => {
		modal.confirm({
			title: "Soll die Begehung wirklich gelöscht werden?",
			icon: <ExclamationCircleOutlined />,
			okText: "Löschen",
			onOk() {
				deleteMutation.mutate(deleteInspectionId);
			},
			maskClosable: true,
			centered: true,
		});
	};

	const inspectionsToDisplay = isViewMobile
		? filterInspectionsForMobile(results, tableFilters, stationNames)
		: results;

	const columnConfig: ColumnsType<Inspection> = isViewMobile
		? getInspectionTableConfigMobile(
				stationNames,
				confirmDeleteInspection,
				userRoles
			)
		: getInspectionTableConfigDesktop(
				stationNames,
				stations || [],
				confirmDeleteInspection,
				userRoles
			);

	if (isError) {
		return (
			<Result
				status="error"
				title="Tabelle konnte nicht geladen werden"
				subTitle={`Error: ${error.message}`}
			/>
		);
	}

	return (
		<>
			<Table
				className={styles["inspection-table"]}
				size={isViewMobile ? "small" : "large"}
				dataSource={inspectionsToDisplay}
				columns={columnConfig}
				tableLayout="fixed"
				scroll={{ x: "85%" }}
				rowKey={(inspection) => {
					const key = inspection.id;
					return `inspection-${key}`;
				}}
				pagination={{
					pageSize: inspectionsPerPage,
					position: ["bottomRight"],
					showSizeChanger: false,
					total: inspectionsToDisplay.length,
				}}
				loading={isPending}
				rowClassName={styles["inspection-table--pointer-cursor"]}
				onChange={(_pagination, filters, _sorter, extra) => {
					if (extra.action === "filter") {
						setTableFilters(filters);
					}
				}}
				onRow={(inspection) => {
					return {
						onClick: (event) => {
							const { target } = event;
							const isClickOnRowValid = isClickOnRowValidConditions(target);
							// preventing opening a modal when clicking delete button
							if (isClickOnRowValid) {
								setSelectedInspection(inspection);
								setReadInspectionKeySuffix(!readInspectionKeySuffix);
								navigate(`/inspections/${inspection.id}`);
							}
						},
					};
				}}
			/>
			{selectedInspection && (
				<ReadInspection
					key={`${selectedInspection.id}-${readInspectionKeySuffix}`}
					inspection={selectedInspection}
					onClose={() => navigate("/inspections/")}
				/>
			)}
		</>
	);
}

export default InspectionsTable;
