import React, { ReactElement, useState } from "react";
import { PlusOutlined } from "@ant-design/icons";
import { useQuery } from "@tanstack/react-query";
import { Button, Col, Row, Tabs } from "antd";
import { subYears } from "date-fns";
import issuesService from "../../services/issuesService";
import { QUERY_KEY_ALL_TRACKED_ISSUES } from "../../utilities/client/query-keys";
import AddIssue from "./AddIssue";
import IssuesTable from "./IssuesTable";
import styles from "./index.module.scss";

function StationIssues(): ReactElement {
	const [isAddIssueModalVisible, setAddIssueModalVisible] =
		useState<boolean>(false);
	const { data: trackedIssuesCollection } = useQuery({
		queryKey: [QUERY_KEY_ALL_TRACKED_ISSUES],
		queryFn: () => {
			const endDate = new Date().toISOString();
			const startDate = subYears(endDate, 1).toISOString();
			return issuesService.fetchIssues({
				start: startDate,
				end: endDate,
				isTracked: true,
			});
		},
	});

	return (
		<div className={styles["station-issues"]}>
			<Row>
				<Col span={24}>
					<h2 className={styles["station-issues__title"]}>Problemmeldungen</h2>
				</Col>
				<Col span={24}>
					<Button
						className={styles["station-issues__new-issue"]}
						type="primary"
						size="middle"
						icon={<PlusOutlined />}
						onClick={() => setAddIssueModalVisible(true)}
						title="Neue Problemmeldung anlegen"
					>
						Neue Problemmeldung
					</Button>
				</Col>
			</Row>

			<Tabs
				className={styles["station-issues__nav-menu"]}
				defaultActiveKey="0"
				items={[
					{
						key: "0",
						label: "Alle",
						children: <IssuesTable issuesPerPage={7} />,
					},
					{
						key: "1",
						label: (
							<>
								Zur Nachverfolgung
								{trackedIssuesCollection?.results.length
									? ` (${trackedIssuesCollection.results.length})`
									: ""}
							</>
						),
						children: <IssuesTable issuesPerPage={7} showTrackedIssuesOnly />,
					},
				]}
			/>
			<AddIssue
				isModalVisible={isAddIssueModalVisible}
				setModalVisible={setAddIssueModalVisible}
			/>
		</div>
	);
}

export default StationIssues;
