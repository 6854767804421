import { CloseOutlined, ExclamationCircleOutlined } from "@ant-design/icons";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { App, Form, Modal } from "antd";
import React, { ReactElement, useState } from "react";
import {
	Inspection,
	InspectionAdd,
	InspectionCollection,
	InspectionIssueAdd,
	postInspection,
} from "../../../utilities/api/jelbi-dashboard-api";
import { QUERY_KEY_ALL_INSPECTIONS } from "../../../utilities/client/query-keys";
import styles from "./index.module.scss";
import InspectionFormPage from "./InspectionFormPage";
import IssuesOverviewFormPage from "./IssuesOverviewFormPage";
import stationEquipmentOptions from "../../../utilities/client/station-equipment";
import { UploadedPhoto } from "../../PhotoUpload";
import useGetUserRoles from "../../../utilities/client/hooks/useGetUserRoles";
import { getHighestUserRole } from "../../../utilities/client/roles.util";

export type InspectionFormData = {
	stationId: string;
	station: string;
	photos: UploadedPhoto[];
	stationEquipment: string[];
	detectedIssues: string[];
	createdAt: Date;
};

export type IssueData = Omit<InspectionIssueAdd, "photoIds"> & {
	photos: UploadedPhoto[];
};

type AddInspectionProps = {
	isModalVisible: boolean;
	setModalVisible: React.Dispatch<React.SetStateAction<boolean>>;
};

function AddInspection({
	isModalVisible,
	setModalVisible,
}: AddInspectionProps): ReactElement {
	const [currentPage, setCurrentPage] = useState(1);
	const queryClient = useQueryClient();
	const { modal } = App.useApp();

	const [inspectionData, setInspectionData] = useState<InspectionFormData>({
		stationId: "",
		station: "",
		photos: [],
		stationEquipment: [],
		detectedIssues: [],
		createdAt: new Date(),
	});
	const [issuesData, setIssuesData] = useState<IssueData[]>([]);

	const { message } = App.useApp();

	const currentUserRoles = useGetUserRoles();

	const closeForm = () => {
		setInspectionData({
			stationId: "",
			station: "",
			photos: [],
			stationEquipment: [],
			detectedIssues: [],
			createdAt: new Date(),
		});
		setIssuesData([]);
		setCurrentPage(1);
		setModalVisible(false);
	};

	const isFormEmpty = () => {
		return !(
			inspectionData.station ||
			inspectionData.photos.length ||
			inspectionData.stationEquipment.length ||
			issuesData.length
		);
	};

	const updateQueryCache = async (addedInspection: Inspection) => {
		const queryKey = [QUERY_KEY_ALL_INSPECTIONS];
		await queryClient.cancelQueries({ queryKey });

		const previousInspections =
			queryClient.getQueryData<InspectionCollection>(queryKey);

		if (previousInspections) {
			queryClient.setQueryData<InspectionCollection>(queryKey, {
				totalCount: previousInspections.totalCount + 1,
				results: [addedInspection, ...previousInspections.results],
			});
		} else {
			queryClient.setQueryData<InspectionCollection>(queryKey, {
				totalCount: 1,
				results: [addedInspection],
			});
		}
	};

	const onSubmitError = () => {
		message.error({
			content: "Begehung nicht übermittelt!",
			key: "failAddInspection",
		});
	};

	const addInspectionMutation = useMutation({
		mutationFn: async (inspection: InspectionAdd) => {
			const response = await postInspection(inspection);

			if (response.status !== 201) {
				return Promise.reject();
			}
			return Promise.resolve(response.data);
		},

		onSuccess: (addedInspection: Inspection) => {
			message.success({
				content: "Begehung übermittelt!",
				key: "successAddInspection",
			});

			updateQueryCache(addedInspection);
			closeForm();
		},
		onError: onSubmitError,
	});

	const addInspection = () => {
		const currentUserHighestRole = getHighestUserRole(currentUserRoles);
		if (currentUserHighestRole) {
			addInspectionMutation.mutate({
				stationId: inspectionData.stationId,
				photoIds: inspectionData.photos
					.filter((photo) => !photo.id.includes("temp"))
					.map((photo) => photo.id),
				createdBy: currentUserHighestRole,
				createdAt: inspectionData.createdAt.toISOString(),
				issues: issuesData.map(
					({ equipment, problem, description, photos }) => ({
						equipment,
						problem,
						description,
						photoIds: photos
							.filter((photo) => !photo.id.includes("temp"))
							.map((photo) => photo.id),
					})
				),
			});
		} else {
			onSubmitError();
		}
	};

	const abort = () => {
		if (isFormEmpty()) {
			closeForm();
		} else {
			modal.confirm({
				title: "Begehung abbrechen und alle Daten verwerfen?",
				okText: "Ok",
				cancelText: "Zurück",
				onOk: closeForm,
				closable: false,
				icon: <ExclamationCircleOutlined />,
				centered: true,
			});
		}
	};

	return (
		<Modal
			title={
				<h2 className={styles["add-inspection-modal__title"]}>Neue Begehung</h2>
			}
			className="add-inspection-modal"
			open={isModalVisible}
			maskClosable={false}
			closable
			closeIcon={
				<CloseOutlined
					className={styles["add-inspection-modal__close-button"]}
				/>
			}
			destroyOnClose
			width={1000}
			footer={null}
			onCancel={abort}
		>
			<Form.Provider
				onFormChange={(name, { forms }) => {
					if (name === "addInspectionForm") {
						// save values to evaluate if the form is empty
						setInspectionData({
							...inspectionData,
							...forms.addInspectionForm.getFieldsValue(),
						});
					}
				}}
				onFormFinish={(name, { forms, values }) => {
					if (name === "addInspectionForm") {
						const detectedIssues: string[] = values.stationEquipment
							? stationEquipmentOptions
									.map((option) => option.value)
									.filter((value) => !values.stationEquipment.includes(value))
							: [];
						setInspectionData({
							...inspectionData,
							stationId: forms.addInspectionForm.getFieldValue("stationId"),
							station: values.station,
							photos: values.photos,
							stationEquipment: values.stationEquipment,
							detectedIssues,
						});
						if (issuesData.length) {
							// remove issues that were submitted before but are not detected anymore
							setIssuesData(
								issuesData.filter((issue) =>
									detectedIssues.includes(issue.equipment)
								)
							);
						}
						setCurrentPage((page) => page + 1);
					}
					if (name === "addIssueForm") {
						// prevent duplicated issues for same equipment
						const filteredIssues = issuesData.filter(
							(issue) => issue.equipment !== values.equipment
						);
						setIssuesData([
							...filteredIssues,
							{
								equipment: values.equipment,
								problem: values.problem,
								description: values.description,
								photos: values.photos ?? [],
							},
						]);
					}
					if (name === "overviewIssuesForm") {
						addInspection();
					}
				}}
			>
				{currentPage === 1 && (
					<InspectionFormPage
						inspectionData={inspectionData}
						onCancel={abort}
					/>
				)}
				{currentPage === 2 && (
					<IssuesOverviewFormPage
						inspectionData={inspectionData}
						issuesData={issuesData}
						completedIssues={
							new Set(issuesData.map((issue) => issue.equipment))
						}
						onBack={() => setCurrentPage((page) => page - 1)}
					/>
				)}
			</Form.Provider>
		</Modal>
	);
}

export default AddInspection;
