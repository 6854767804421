import React from "react";
import { DeleteOutlined, FilterOutlined } from "@ant-design/icons";
import { Tag } from "antd";
import { ColumnsType } from "antd/lib/table";
import { FilterValue } from "antd/lib/table/interface";
import {
	Inspection,
	Station,
	StationPartial,
	UserRole,
} from "../../../utilities/api/jelbi-dashboard-api";
import { humanizedDateTimeFormat } from "../../../utilities/client/date-format";
import styles from "./index.module.scss";
import { isAdminOrHigher } from "../../../utilities/client/roles.util";

const PROBLEM_STATION_COLUMN_KEY = "problem+station";

function adjustConfigForUserRole(
	config: ColumnsType<Inspection>,
	userRoles: UserRole[]
) {
	if (!isAdminOrHigher(userRoles)) {
		return config.filter((columnConfig) => columnConfig.key !== "actions");
	}
	return config;
}

export const getInspectionTableConfigMobile = (
	stationNames: string[],
	deleteInspection: (inspectionId: string) => void,
	userRoles: UserRole[]
): ColumnsType<Inspection> => {
	const config: ColumnsType<Inspection> = [
		{
			key: PROBLEM_STATION_COLUMN_KEY,
			title: (
				<span className={styles["inspection-table__column-header"]}>
					Problem/Standort
				</span>
			),
			render: (inspection: Inspection) => {
				const problemCount = inspection.issues.length;
				const tagText =
					problemCount + (problemCount === 1 ? " Problem" : " Probleme");

				return (
					<>
						{problemCount > 0 && (
							<Tag className={styles["inspection-table__problem-count-tag"]}>
								<span>{tagText}</span>
							</Tag>
						)}

						<div className={styles["inspection-table__station-text"]}>
							{inspection.station.name}
						</div>
					</>
				);
			},
			filters: [
				{
					text: "Probleme",
					value: "problem",
					children: [
						{
							text: "Begehungen ohne Problemmeldungen",
							value: false,
						},
						{
							text: "Begehungen mit Problemmeldungen",
							value: true,
						},
					],
				},
				{
					text: "Standort",
					value: "station",
					children: stationNames
						.map((stationName) => {
							return { text: stationName, value: stationName };
						})
						.sort((a, b) => a.text.localeCompare(b.text)),
				},
			],
			filterMode: "tree",
			filterIcon: <FilterOutlined />,
		},
		{
			key: "createdAt",
			title: (
				<span className={styles["inspection-table__column-header"]}>Datum</span>
			),
			dataIndex: "createdAt",
			width: 87,
			render: (reportedAtDate: string) => {
				return humanizedDateTimeFormat(new Date(reportedAtDate));
			},
		},
		{
			key: "actions",
			width: 40,
			render: (_, inspection) => {
				return (
					<DeleteOutlined
						className={styles["inspection-table__action"]}
						title="Löschen"
						onClick={() => deleteInspection(inspection.id)}
					/>
				);
			},
			align: "center",
		},
	];
	return adjustConfigForUserRole(config, userRoles);
};

export const filterInspectionsForMobile = (
	inspections: Inspection[],
	tableFilters: Record<string, FilterValue | null>,
	stationNames: string[]
): Inspection[] => {
	const relevantFilters = tableFilters[PROBLEM_STATION_COLUMN_KEY];
	if (relevantFilters) {
		const filterStationNames = relevantFilters.filter(
			(filter) => typeof filter === "string" && stationNames.includes(filter)
		);
		const filterIssueCount = relevantFilters.filter(
			(filter) => typeof filter === "boolean"
		);

		if (filterStationNames.length > 0 && filterIssueCount.length > 0) {
			// filter for both with AND
			return inspections.filter(
				(inspection) =>
					filterStationNames.includes(inspection.station.name) &&
					filterIssueCount.includes(inspection.issues.length > 0)
			);
		}
		if (filterStationNames.length > 0) {
			// only filter for station
			return inspections.filter((inspection) =>
				filterStationNames.includes(inspection.station.name)
			);
		}
		if (filterIssueCount.length > 0) {
			// only filter for issue count
			return inspections.filter((inspection) =>
				filterIssueCount.includes(inspection.issues.length > 0)
			);
		}
	}

	// do not filter
	return inspections;
};

export const getInspectionTableConfigDesktop = (
	inspectionStationNames: string[],
	stations: Station[],
	deleteInspection: (inspectionId: string) => void,
	userRoles: UserRole[]
): ColumnsType<Inspection> => {
	const config: ColumnsType<Inspection> = [
		{
			key: "station",
			title: (
				<span className={styles["inspection-table__column-header"]}>
					Standort
				</span>
			),
			dataIndex: "station",
			render: (station: StationPartial) => station.name,
			filters: inspectionStationNames
				.map((stationName) => {
					const { shortName } =
						stations.find((station) => station.name === stationName) || {};
					const fullStationName = `${shortName ? `${shortName} ` : ""}${stationName}`;
					return { text: fullStationName, value: stationName };
				})
				.sort((a, b) =>
					a.text
						.substring(a.text.indexOf(" ") + 1)
						.localeCompare(b.text.substring(b.text.indexOf(" ") + 1))
				),
			onFilter: (stationName, record) => record.station.name === stationName,
			filterSearch: true,
			filterIcon: <FilterOutlined />,
		},
		{
			key: "inspectionWithIssue",
			title: (
				<span className={styles["inspection-table__column-header"]}>
					Probleme
				</span>
			),
			dataIndex: "issues",
			align: "center",
			width: 200,
			render: (issues: object[]) => (issues.length ? "ja" : "nein"),
			filters: [
				{
					text: "Begehungen ohne Problemmeldungen",
					value: false,
				},
				{
					text: "Begehungen mit Problemmeldungen",
					value: true,
				},
			],
			onFilter: (value, record) => record.issues.length > 0 === value,
			filterMode: "tree",
			filterIcon: <FilterOutlined />,
		},
		{
			key: "issueCount",
			title: (
				<span className={styles["inspection-table__column-header"]}>
					Problemanzahl
				</span>
			),
			dataIndex: "issues",
			align: "center",
			width: 200,
			render: (issues: object[]) => issues.length,
		},
		{
			key: "createdAt",
			title: (
				<span className={styles["inspection-table__column-header"]}>Datum</span>
			),
			dataIndex: "createdAt",
			width: 200,
			render: (reportedAtDate: string) => {
				return humanizedDateTimeFormat(new Date(reportedAtDate));
			},
		},
		{
			key: "actions",
			title: (
				<span className={styles["inspection-table__column-header"]}>
					Aktion
				</span>
			),
			width: 250,
			render: (_, inspection) => {
				return (
					<DeleteOutlined
						className={styles["inspection-table__action"]}
						title="Löschen"
						onClick={() => deleteInspection(inspection.id)}
					/>
				);
			},
			align: "center",
		},
	];

	return adjustConfigForUserRole(config, userRoles);
};
