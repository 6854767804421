import React, { ReactElement } from "react";
import { Table } from "antd";
import { ColumnGroupType, ColumnType, ColumnsType } from "antd/lib/table";
import styles from "./index.module.scss";
import VehicleImage from "../../VehicleImage";
import {
	VehicleType,
	Vehicles,
} from "../../../utilities/api/jelbi-dashboard-api";
import { isMicromobility } from "../../../utilities/client/vehicles";

type VehicleAppearanceStatsTableProps = {
	vehicles: Vehicles;
};

const CONTENT_FONTSIZE = 12;
const TABLE_CONTENT_FONTSIZE = 14;
const rowheaderStyle: React.CSSProperties = {
	fontSize: 14,
};

const createVehicleColumn = (
	vehicleType: VehicleType
): ColumnGroupType<TableData> | ColumnType<TableData> => ({
	title: <VehicleImage vehicleType={vehicleType} size={28} />,
	dataIndex: "vehicleValues",
	key: vehicleType,
	align: "center",
	render: (vehicleValues: Partial<Record<VehicleType, number>>, record) => {
		if (record.key === "live") {
			const vehicleCount = vehicleValues[vehicleType];
			const color = record.availabilitiesBelowThreshold?.some(
				(vehicle) => vehicle === vehicleType
			)
				? "red"
				: "green"; // low
			const style = { fontSize: TABLE_CONTENT_FONTSIZE, color };

			return <span style={style}>{vehicleCount}</span>;
		}
		return (
			<span style={{ fontSize: TABLE_CONTENT_FONTSIZE }}>
				{vehicleValues[vehicleType]}
			</span>
		);
	},
});

const columns: ColumnsType<TableData> = [
	{
		dataIndex: "header",
		key: "header",
		render: (rowHeader) => (
			<span style={{ fontSize: CONTENT_FONTSIZE }}>{rowHeader}</span>
		),
	},
	createVehicleColumn(VehicleType.Car),
	createVehicleColumn(VehicleType.StationBasedCar),
	createVehicleColumn(VehicleType.MotorScooter),
	createVehicleColumn(VehicleType.Bicycle),
	createVehicleColumn(VehicleType.KickScooter),
];

type TableData = {
	key: "live" | "capacity" | "threshold";
	header: ReactElement;
	vehicleValues: {
		CAR?: number;
		BICYCLE?: number;
		MOTOR_SCOOTER?: number;
		KICK_SCOOTER?: number;
		STATION_BASED_CAR?: number;
	};
	availabilitiesBelowThreshold?: VehicleType[];
};

export const getTableData = (dataSource: Vehicles): TableData[] => {
	const tableDataSource: TableData[] = [
		{
			key: "live",
			header: <span style={rowheaderStyle}>Live-Anzahl</span>,
			vehicleValues: {},
			availabilitiesBelowThreshold: [],
		},
		{
			key: "capacity",
			header: <span style={rowheaderStyle}>Kapazität</span>,
			vehicleValues: {},
		},
		{
			key: "threshold",
			header: <span style={rowheaderStyle}>Grenzwert</span>,
			vehicleValues: {},
		},
	];

	dataSource.forEach((vehicle) => {
		const { availability, capacity, expectation, vehicleType } = vehicle;

		tableDataSource.forEach((tableData) => {
			switch (tableData.key) {
				case "live":
					if (availability < expectation.expected) {
						tableData.availabilitiesBelowThreshold?.push(vehicleType);
					}
					// eslint-disable-next-line no-param-reassign
					tableData.vehicleValues[
						vehicleType as Exclude<VehicleType, VehicleType.Private>
					] = availability;
					break;
				case "capacity":
					// eslint-disable-next-line no-param-reassign
					tableData.vehicleValues[
						vehicleType as Exclude<VehicleType, VehicleType.Private>
					] = capacity;
					break;
				case "threshold":
					// eslint-disable-next-line no-param-reassign
					tableData.vehicleValues[
						vehicleType as Exclude<VehicleType, VehicleType.Private>
					] = expectation.expected;
					break;
				default:
					break;
			}
		});
	});

	return tableDataSource;
};

function VehicleAppearanceStatsTable({
	vehicles,
}: VehicleAppearanceStatsTableProps): ReactElement {
	const vehiclesWithKey = vehicles.map((vehicle) => {
		return { key: vehicle.vehicleType, ...vehicle };
	});

	const filteredDataSource = vehiclesWithKey.filter(
		(vehicle) => isMicromobility(vehicle.vehicleType) || vehicle.capacity > 0
	);

	const updatedColumns = columns.filter(
		(column) =>
			filteredDataSource.find(
				(vehicle) => vehicle.vehicleType === column.key
			) || column.key === "header"
	);

	return (
		<div className={styles["vehicle-appearance-table"]}>
			<Table
				size="small"
				dataSource={getTableData(filteredDataSource)}
				columns={updatedColumns}
				pagination={false}
			/>
		</div>
	);
}

export default VehicleAppearanceStatsTable;
