import { useQuery } from "@tanstack/react-query";
import { AutoComplete, Col, Form, Row, Button, Checkbox } from "antd";
import React, { ReactElement, useEffect, useState } from "react";
import stationService from "../../../../services/stationService";
import { QUERY_KEY_ALL_STATIONS } from "../../../../utilities/client/query-keys";
import styles from "./index.module.scss";
import PhotoUpload, { UploadedPhoto } from "../../../PhotoUpload";
import filterForExistingStations from "../../../../utilities/client/existing-stations-dropdown";
import stationEquipmentOptions from "../../../../utilities/client/station-equipment";
import useIsMobileView from "../../../../utilities/client/hooks/useIsMobileView";
import { InspectionFormData } from "..";

type InspectionFormPageProps = {
	inspectionData: InspectionFormData;
	onCancel: () => void;
};

type DropdownOption = {
	key: string;
	value: string;
};

const COL_SPAN_FULL_ROW = 24;

function InspectionFormPage({
	inspectionData,
	onCancel,
}: InspectionFormPageProps): ReactElement {
	const [stationDropdownOptions, setStationDropdownOptions] =
		useState<DropdownOption[]>();
	const [form] = Form.useForm();
	const [isContinueButtonEnabled, setIsContinueButtonEnabled] = useState(false);
	const { data: stations } = useQuery({
		queryKey: [QUERY_KEY_ALL_STATIONS],
		queryFn: () => stationService.fetchStations(),
	});

	const stationEquipmentCheckboxes: ReactElement[] =
		stationEquipmentOptions.map((item) => {
			return (
				<Col
					key={item.key}
					span={useIsMobileView() ? 12 : 6}
					className={styles["add-inspection-form__checkbox"]}
				>
					<Checkbox value={item.value}>{item.label}</Checkbox>
				</Col>
			);
		});

	useEffect(() => {
		if (stations) {
			setStationDropdownOptions(filterForExistingStations(stations));
		}
	}, [stations?.length]);

	// this is needed to activate the continue button if the user navigates back to this form
	// just checking if one of required form values is not empty is enough
	useEffect(() => {
		if (inspectionData.station !== "") {
			setIsContinueButtonEnabled(true);
		}
	}, []);

	return (
		<Form
			name="addInspectionForm"
			onAbort={() => form.resetFields()}
			layout="vertical"
			form={form}
			className={styles["add-inspection-form"]}
			preserve={false}
			initialValues={inspectionData}
			onValuesChange={(_, { photos: photosForm, station }) => {
				if (
					photosForm &&
					photosForm.length &&
					!photosForm.some((photo: UploadedPhoto) =>
						photo.id.includes("temp")
					) &&
					station &&
					station !== ""
				) {
					setIsContinueButtonEnabled(true);
				} else {
					setIsContinueButtonEnabled(false);
				}
			}}
		>
			<Row gutter={[18, 12]}>
				<Col span={COL_SPAN_FULL_ROW}>
					<Form.Item
						label="STANDORT"
						name="station"
						rules={[
							{
								required: true,
								message: "Bitte wähle eine Station aus",
							},
						]}
					>
						<AutoComplete
							options={stationDropdownOptions}
							onSelect={(_, option) =>
								form.setFieldValue("stationId", option.key)
							}
							filterOption
							className={styles["add-inspection-form__input"]}
							placeholder="Bitte wählen"
							allowClear
						/>
					</Form.Item>
				</Col>
				<Col span={COL_SPAN_FULL_ROW}>
					<Form.Item label="AUSSTATTUNG" name="stationEquipment">
						<Checkbox.Group>
							<Row>{stationEquipmentCheckboxes}</Row>
						</Checkbox.Group>
					</Form.Item>
				</Col>
				<Col span={COL_SPAN_FULL_ROW}>
					<Form.Item
						label={`FOTOS (${inspectionData.photos.length}/2)`}
						name="photos"
						help="Füge mindestens ein Foto hinzu"
						rules={[
							{
								required: true,
							},
						]}
					>
						<PhotoUpload maxItems={2} initialPhotos={inspectionData.photos} />
					</Form.Item>
				</Col>
				<Col
					span={COL_SPAN_FULL_ROW}
					className={styles["add-inspection-form__footer"]}
				>
					<Button type="default" onClick={onCancel} className={styles.button}>
						Abbrechen
					</Button>
					<Button
						type="primary"
						htmlType="submit"
						className={styles.button}
						disabled={!isContinueButtonEnabled}
					>
						Weiter
					</Button>
				</Col>
			</Row>
		</Form>
	);
}

export default InspectionFormPage;
