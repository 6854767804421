import React, { ReactElement, useState } from "react";
import { Switch } from "@mantine/core";
import { Station } from "../../../utilities/api/jelbi-dashboard-api";
import VehicleAccumulated from "./VehicleAccumulated";
import styles from "./VehicleSummary.module.scss";

type VehicleSummaryProps = {
	station: Station;
	accumulateOverWeek: boolean;
};

type DistributionType = "MODE" | "MSP";

function VehicleSummary({
	station,
	accumulateOverWeek,
}: VehicleSummaryProps): ReactElement {
	const [distributionType, setDistributionType] =
		useState<DistributionType>("MODE");

	const toggleMsp = (checked: boolean) => {
		setDistributionType(checked ? "MSP" : "MODE");
	};

	return (
		<div className={styles["vehicle-summary"]}>
			<div className={styles["vehicle-summary__config"]}>
				<div className={styles["vehicle-summary__summary-type"]}>
					<Switch
						className={styles["vehicle-summary__partition-headline"]}
						labelPosition="left"
						label="Zeige MSPs"
						onChange={(e) => toggleMsp(e.currentTarget.checked)}
						disabled={accumulateOverWeek}
					/>
				</div>
			</div>
			<div>
				<VehicleAccumulated
					station={station}
					accumulateOverWeek={accumulateOverWeek}
					showMsps={distributionType === "MSP"}
				/>
			</div>
		</div>
	);
}

export default VehicleSummary;
